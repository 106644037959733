import isEqual from 'lodash/isEqual';
import { clearPrimary, listPublicPlans, queryPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import {
  PublicPlan,
  ListPublicPlansRequest,
  QueryPublicPlansRequest,
  QueryV2,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PLANS_QUERY_PAGE_SIZE, PLAN_COUNT_LIMIT } from '../constants';
import { memoize } from '../utils/fp';
import { wrapMethods } from '../utils/object';

export function createPlansApi(httpClient: IHttpClient, useNewLimit: boolean) {
  return new PlansApi(httpClient, useNewLimit);
}

export function memoizePlansApi(api: PlansApi, compare: (a: any[], b: any[]) => boolean = isEqual): PlansApi {
  return wrapMethods(api, (o, name) => memoize<any>(o[name].bind(o), compare));
}

type Filter = { exclude: string[] } | { include: string[] };
export type PlansQuery = Filter & { page?: number };

export class PlansApi {
  constructor(private httpClient: IHttpClient, private useNewLimit: boolean) {}

  async loadPaidPlans(request?: ListPublicPlansRequest): Promise<PublicPlan[]> {
    const defaultRequest = this.useNewLimit ? { limit: PLAN_COUNT_LIMIT } : {};
    const response = await this.httpClient.request(listPublicPlans(request ?? defaultRequest));
    return response.data.plans || [];
  }

  async query(query: Partial<PlansQuery> = {}): Promise<PublicPlan[]> {
    const response = await this.httpClient.request(
      queryPublicPlans(queryToRequest({ exclude: [], ...query }, this.useNewLimit)),
    );
    return response.data.plans || [];
  }

  clearPrimaryPlan() {
    return this.httpClient.request(clearPrimary({}));
  }
}

function queryToRequest(query: PlansQuery, useNewLimit: boolean): QueryPublicPlansRequest {
  const limit = useNewLimit ? PLAN_COUNT_LIMIT : PLANS_QUERY_PAGE_SIZE;
  return {
    query: {
      paging: {
        limit,
        offset: query.page ? query.page * limit : undefined,
      },
      filter: queryToFilter(query),
    },
  };
}

function queryToFilter(query: Filter): QueryV2['filter'] {
  if ('exclude' in query && query.exclude.length > 0) {
    return { $not: { id: { $in: query.exclude } } };
  }
  if ('include' in query && query.include.length > 0) {
    return { id: { $in: query.include } };
  }
}

import { IntegrationData, StatusData } from '../types/common';

export enum PageType {
  DEFAULT_SUCCESS,
  VERTICAL_SUCCESS,
  ERROR,
  OWNER_DEMO,
}

export const getPageType = (
  status: Partial<Pick<StatusData, 'ownerDemo' | 'successful'>>,
  integrationData: IntegrationData,
) => {
  let pageType = PageType.ERROR;
  if (status.successful) {
    pageType = PageType.DEFAULT_SUCCESS;
    if (status.ownerDemo) {
      pageType = PageType.OWNER_DEMO;
    } else if (integrationData.navigateToSectionProps || integrationData.navigateToPageProps) {
      pageType = PageType.VERTICAL_SUCCESS;
    }
  }
  return pageType;
};

import { ControllerFlowAPI, IWixStatic } from '@wix/yoshi-flow-editor';
import { BIRole, userRoleToBIRole } from '../utils/bi';

export interface CommonBIData {
  appInstanceId: string;
  metaSiteId?: string;
  biToken?: string;
  visitor_id?: string;
  site_member_id?: string;
  role?: BIRole;
}

export const commonBIDataFromFlowAPI = (flowAPI: ControllerFlowAPI): CommonBIData => {
  const memberRoles: BIRole[] = ['owner', 'member'];
  const bi = flowAPI.controllerConfig.platformAPIs.bi;
  const user = flowAPI.controllerConfig.wixCodeApi.user.currentUser;
  const role = userRoleToBIRole(user.role);
  return {
    appInstanceId: flowAPI.controllerConfig.appParams.instanceId,
    metaSiteId: bi?.metaSiteId,
    biToken: bi?.biToken,
    role,
    site_member_id: bi?.siteMemberId ?? (memberRoles.includes(role) ? user.id : undefined),
    visitor_id: bi?.visitorId,
  };
};

export const commonBIDataFromWixSdk = (Wix: IWixStatic): CommonBIData => {
  const uid = Wix.Utils.getUid();
  return {
    appInstanceId: Wix.Utils.getInstanceId(),
    metaSiteId: Wix.Utils.getInstanceValue('metaSiteId'),
    biToken: Wix.Utils.getInstanceValue('biToken'),
    role: Wix.Utils.getPermissions() === 'OWNER' ? 'owner' : uid ? 'member' : 'visitor',
    site_member_id: uid,
    visitor_id: Wix.Utils.getInstanceValue('aid') || uid,
  };
};
